import { Component } from '../common/decorators';

import { HourMinTime } from '../common/components/time.component';
import './Overview.component';

import { AppModel } from './app.model';

import '../common/components/TimeLabel.component';
import '../schedule/Schedule.component';
import '../schedule/ScheduleConfig.component';

export enum ChangeType {
    HOURS, MINUTES, RECESS
}

@Component({
    template: `
    <section layout-fill layout="row" flex>
        
        <md-sidenav class="md-sidenav-left" md-component-id="left">
        <md-content flex>
            <mft-schedule-config config="$ctrl.appModel.scheduleConfig" schedule="$ctrl.appModel.schedule"></mft-schedule-config>   
        </md-content>
        </md-sidenav>
        
        <div layout="column" layout-fill flex >
            <md-toolbar flex="5">
                <div class="md-toolbar-tools mft-toolbar">
                    <md-button class="md-icon-button" ng-click="$ctrl.toggleSideNav()" aria-label="Settings">
                        <ng-md-icon icon="menu"></ng-md-icon> 
                    </md-button>
                    <div class="mft-toolbar-right">        
                        <img class="mft-toolbar-logo" src="img/myFlexitime-logo.svg" alt="myFlexitime-logo">
                    </div>  
                </div>
            </md-toolbar>

            <md-content layout-fill flex layout="column" class="main-content">
         
                <mft-overview flex-xs="30" hide-gt-xs overview="$ctrl.appModel.overview" diameter="160" layout="column" class="mft-overview" ></mft-overview>
                <mft-overview hide-xs flex-sm="35" hide-gt-sm overview="$ctrl.appModel.overview" diameter="240" layout="column" class="mft-overview medium" ></mft-overview>
                <mft-overview hide-xs hide-sm flex-gt-sm="25" overview="$ctrl.appModel.overview" diameter="200" layout="column" class="mft-overview large" ></mft-overview>
                
                <mft-schedule flex-xs="45" flex-sm="25" flex-gt-sm="35" days="$ctrl.appModel.schedule.days" class="schedule" update="$ctrl.manageUpdate()"></mft-schedule>
         
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                <div flex-xs="10" flex-sm="25" flex-gt-sm="20" class="ads">
                    <ins layout="column" class="adsbygoogle ads"
                    style="display:block;"
                    data-ad-client="ca-pub-9109056131794961"
                    data-ad-slot="3844604733"
                    data-ad-format="auto"
                    ></ins>
                </div>    
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>

                <div id="footer" flex-xs="5" flex-sm="5" flex-gt-md="5" layout="column">
                    <div class="footer-content">
                            <small>&copy;&nbsp;{{$ctrl.getCurrentYear()}}&nbsp;www.myflexitime.com</small>
                        <a href="privacypolicy.html" target="_blank"><small>Privacy</small></a>&nbsp;·&nbsp;
                        <a href="disclaimer.html" target="_blank"><small>Disclaimer</small></a>
                    </div>
                </div>
            </md-content>
        </div>

    </section>
  `
})
export class AppComponent {

    public appModel: AppModel;
    /** @ngInject */
    constructor($scope: any, private $mdSidenav: angular.material.ISidenavService) {
        this.appModel = new AppModel();
    };

    toggleSideNav(): void {
        this.$mdSidenav("left").toggle();
    }

    manageUpdate() {
        this.appModel.updateTotalOverview();
    }

    getCurrentYear() {
        return new Date().getFullYear();
    }
}
