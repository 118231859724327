import { Component } from '../../common/decorators';

export interface HourMinTime {
    hours: number;
    minutes: number;
}

interface HourMinTimeViewValue {
    hours: string;
    minutes: string;
}

@Component({
    bindings: {
        time: '='
    },
    template: `
    <div layout="row" class="hour-min">
            <input ng-model="$ctrl.time.hours" ng-model-options="{ allowInvalid: true, updateOn: 'blur' }"
             type="number" name="hours" min="0" max="23" ng-maxlength="2" mft-double-zero required/>
            <span>:</span>
            <input ng-model="$ctrl.time.minutes" ng-model-options="{ allowInvalid: true, updateOn: 'blur' }"
             type="number" name="minutes" min="0" max="59" ng-maxlength="2" mft-double-zero required/>
    </div>
   `
})
export class TimeComponent {
}