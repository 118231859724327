import { Component } from '../common/decorators';
import { ScheduleDay } from './ScheduleDay.component';
import { HourMinTime } from '../common/components/Time.component'

import './ScheduleDay.component';

export interface Schedule {
    days: ScheduleDay[];
}

@Component({
    bindings: {
        days: '=',
        update: '&'
    },
    template: `
        <md-tabs hide-gt-sm md-border-bottom="true" md-swipe-content="true" md-center-tabs="true">
            <md-tab label="{{day.shortName}}" ng-disabled="!day.enabled" ng-repeat="day in $ctrl.days" >
                <md-content>
                    <mft-schedule-day day="day" flex="" ng-if="day.enabled" class="animate-if" update="$ctrl.manageUpdate()"></mft-schedule-day>  
                </md-content>
            </md-tab>
        </md-tabs>

        <md-content layout="row" hide-xs hide-sm >
            <mft-schedule-day class="large" ng-repeat="day in $ctrl.days" day="day" flex-xs="50" flex-sm="33" flex-gt-md="" flex="" ng-if="day.enabled" class="animate-if" update="$ctrl.manageUpdate()"></mft-schedule-day>  
        </md-content>

 <!--   <div layout="row" flex layout-padding >
        <mft-schedule-day ng-repeat="day in $ctrl.days" day="day" flex-xs="50" flex-sm="33" flex-gt-md="" flex="" ng-if="day.enabled" class="animate-if" update="$ctrl.manageUpdate()"></mft-schedule-day>  
    </div> -->
  `
})
export class ScheduleComponent {
    
    public update: () => {};

    manageUpdate() {
        this.update();
    }
}