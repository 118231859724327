import { Component } from '../common/decorators';
import { SelectedDay } from './ScheduleConfig.model'

@Component({
  bindings: {
    days: "=",
    updateDays: "&"
  },
  template: `
    <div class="valid-days">
  <md-switch ng-repeat="day in $ctrl.days" ng-model="day.enabled" aria-label="{{day.name}}'s switch" ng-change="$ctrl.manageChange(day)">
    {{day.name}}&nbsp;{{ day.enabled ? " (Enabled)" : " (Disabled)"}}
  </md-switch>
          
    </div>
  `
})
export class ValidDaysComponent {

  updateDays: (day: any) => void;

  manageChange(day: SelectedDay) {
    this.updateDays({ day: day })
  }
}