
import { AppComponent } from './app/App.component';
import { OverviewComponent } from './app/Overview.component';
import { TimeLabelComponent } from './common/components/TimeLabel.component';
import { FromToTimeComponent } from './common/components/FromToTime.component';
import { ScheduleConfigComponent } from './schedule/ScheduleConfig.component';
import { ValidDaysComponent } from './schedule/ValidDays.component';
import { ScheduleComponent } from './schedule/Schedule.component';
import { ScheduleDayComponent } from './schedule/ScheduleDay.component';
import { DayCalculatorComponent } from './schedule/DayCalculator.component';
import { TimeComponent } from './common/components/time.component';
import { DayInfoComponent } from './common/components/DayInfo.component';

angular.module("MyFlexitime", ['ngMessages', 'ngMaterial', 'ngMdIcons', 'ngAnimate'])
    .component('mftApp', AppComponent)
    .component('mftOverview', OverviewComponent)
    .component('mftTimeLabel', TimeLabelComponent)
    .component('mftFromToTime', FromToTimeComponent)
    .component('mftScheduleConfig', ScheduleConfigComponent)
    .component('mftValidDays', ValidDaysComponent)
    .component('mftSchedule', ScheduleComponent)
    .component('mftScheduleDay', ScheduleDayComponent)
    .component('mftDayCalculator', DayCalculatorComponent)
    .component('mftHourMinuteTime', TimeComponent)
    .component('mftDayInfo', DayInfoComponent)

    .config(["$mdThemingProvider", $mdThemingProvider => {

        $mdThemingProvider.theme('default')
            .primaryPalette('blue-grey', {
                //  'default': '400', // by default use shade 400 from the pink palette for primary intentions
                // 'hue-1': '100', // use shade 100 for the <code>md-hue-1</code> class
                // 'hue-2': '600', // use shade 600 for the <code>md-hue-2</code> class
                // 'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
            })
            // If you specify less than all of the keys, it will inherit from the
            // default shades
            .accentPalette('red', {
                // 'default': '200' // use shade 200 for default, and keep all other shades the same
            });
    }]);
