import { Component } from '../common/decorators';

import { DayInfo } from '../common/components/DayInfo.component';
import '../common/components/DayInfo.component';

import { HourMinTime } from '../common/components/Time.component'

export interface ScheduleDay {
  name: string;
  shortName: string;
  dayInfo: DayInfo;
  enabled: boolean;
}

@Component({
  bindings: {
    day: '=',
    update: '&'
  },
  template: `
  <div class="schedule-day" layout="column" flex>
    <span class="day-name">{{$ctrl.day.name}}</span>
    <mft-day-info ng-model="$ctrl.day.dayInfo" update="$ctrl.manageUpdate(fieldToUpdate)"/>
  </div>
`
})
export class ScheduleDayComponent {

  public update: () => {};

  manageUpdate(fieldToUpdate: any) {
    this.update();
  }

}
