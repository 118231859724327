import { Component } from '../common/decorators';

import { HourMinTime } from '../common/components/Time.component';

@Component({
    bindings: {
        day: '<',
        weekly: '<'
    },
    template: `
    <h5>{{$ctrl.toTimeString($ctrl.day.startingTime)}} - {{$ctrl.toTimeString($ctrl.day.finishingTime)}}</h5> 
    <h5> - {{$ctrl.day.recess}}min </h5> 
  
    <h4> {{$ctrl.toHourMinTimeString($ctrl.day.realWorkingHours)}}</h4>

  `
})
export class DayCalculatorComponent {
    toHourMinTimeString(t: HourMinTime) {
        return `${t.hours}h ${t.minutes}min`;
    }
    toTimeString(t: HourMinTime) {
        return `${t.hours}:${t.minutes}`;
    }
}

