import { Component } from '../common/decorators';

import { ScheduleConfig, SelectedDay } from './ScheduleConfig.model';

import { FieldToUpdate } from '../common/components/DayInfo.component';
import '../common/components/DayInfo.component';

@Component({
    bindings: {
        config: "=",
        schedule: "=",
    },
    template: `
    <ng-form class="schedule-config" name="$ctrl.configForm">
        <div class="hours-week">
            <md-input-container>
                <label>Hours/week</label>
                <input type="number" min="1" max="168" name="hoursPerWeek" ng-model="$ctrl.config.hoursPerWeek" ng-change="$ctrl.updateHoursPerWeek()"/>
             <div ng-messages="$ctrl.configForm.hoursPerWeek.$error">
                <div class="error-message" ng-message="required">Tell us how many hours you work per week.</div>
                <div class="error-message" ng-message="min">Yep....you wish!. Please add a number greater than 0.</div>
                <div class="error-message" ng-message="number">This is clearly not a valid number. Try using numbers bigger than 0.</div>
                <div class="error-message" ng-message="max">Mmmmm, you cannot work more than 24x7...please fix it.</div>
            </div>
            </md-input-container>
        </div>
        <h4>Worktime (average)</h4>
        <mft-day-info ng-model="$ctrl.config.dayInfo" class="day-info" update="$ctrl.update(fieldToUpdate)"></mft-day-info>
        <h4>Days you work</h4>
        <mft-valid-days days="$ctrl.config.days" update-days="$ctrl.updateDays(day)"/>
    </ng-form>
  `
})
export class ScheduleConfigComponent {

    config: ScheduleConfig;

    update(fieldToUpdate: FieldToUpdate) {

        switch (fieldToUpdate) {
            case FieldToUpdate.STARTING_TIME:
                this.config.startingTime$.next(this.config.dayInfo.startingTime);
                break;
            case FieldToUpdate.FINISHING_TIME:
                this.config.finishingTime$.next(this.config.dayInfo.finishingTime);
                break;
            case FieldToUpdate.RECESS_MINS:
                this.config.recessMins$.next(this.config.dayInfo.recessMins);
                break;
        }
    }

    updateDays(day: SelectedDay) {
        this.config.changedDays$.next(day);
    }

    updateHoursPerWeek() {
        this.config.hoursPerWeek$.next(this.config.hoursPerWeek);
    }
}