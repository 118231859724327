import { ScheduleConfig, SelectedDay } from '../schedule/ScheduleConfig.model';
import { realWorkingMinutes, minutesToHoursMinutes, diffHoursMinutes, hoursToHoursMinutes } from '../schedule/TimeCalculator.model';
import { Schedule } from '../schedule/Schedule.component';
import { HourMinTime } from '../common/components/time.component';
import { Overview } from './Overview.component';

export class AppModel {

    public scheduleConfig: ScheduleConfig;
    public schedule: Schedule;
    public overview: Overview;

    constructor() {

        let days: SelectedDay[] = [
            { name: "Monday", shortName: "Mon", enabled: true },
            { name: "Tuesday", shortName: "Tue", enabled: true },
            { name: "Wednesday", shortName: "Wed", enabled: true },
            { name: "Thursday", shortName: "Thu", enabled: true },
            { name: "Friday", shortName: "Fri", enabled: true },
            { name: "Saturday", shortName: "Sat", enabled: false },
            { name: "Sunday", shortName: "Sun", enabled: false }
        ];

        this.overview = {
            difference: { hours: 0, minutes: 0 },
            planned: { hours: 0, minutes: 0 },
            total: { hours: 0, minutes: 0 }
        }

        this.schedule = {
            days: days.map(day => {
                return {
                    name: day.name,
                    dayInfo: {
                        startingTime: new Date(),
                        finishingTime: new Date(),
                        recessMins: 0,
                    },
                    shortName: day.shortName,
                    enabled: day.enabled
                };
            })
        }

        this.scheduleConfig = new ScheduleConfig(days);

        this.scheduleConfig.changedDays$.subscribe(changedDay => {
            if (changedDay) {
                let day = this.schedule.days.filter(day => day.name === changedDay.name)[0];
                day.enabled = changedDay.enabled;
                this.updateTotalOverview();
            }
        });

        this.scheduleConfig.startingTime$.subscribe(startingTime => {

            this.schedule.days.forEach(day => {
                day.dayInfo.startingTime = startingTime;
            });

            this.updateTotalOverview();
        });

        this.scheduleConfig.finishingTime$.subscribe(finishingTime => {
            this.schedule.days.forEach(day => day.dayInfo.finishingTime = finishingTime);
            this.updateTotalOverview();
        });

        this.scheduleConfig.recessMins$.subscribe(recessMins => {
            this.schedule.days.forEach(day => day.dayInfo.recessMins = recessMins);
            this.updateTotalOverview();
        });

        this.scheduleConfig.hoursPerWeek$.subscribe(hoursPerWeek => {
            this.updatePlannedOverview(hoursPerWeek);
        });
    }

    public updateTotalOverview(): void {

        let totalMinutes = this.schedule.days
            .filter(day => day.enabled)
            .map(day => day.dayInfo)
            .reduce((previous, current, currentIndex, array) => {
                let totalMinutes = realWorkingMinutes(current.startingTime, current.finishingTime, current.recessMins);
                return previous + totalMinutes;
            }, 0);

        let total = minutesToHoursMinutes(totalMinutes);
        let difference = diffHoursMinutes(this.overview.planned, total);

        // Return new object to force data rerendering
        this.overview = {
            total: total,
            planned: this.overview.planned,
            difference: difference,
        }
    }

    public updatePlannedOverview(hoursPerWeek: number): void {

        let planned = hoursToHoursMinutes(hoursPerWeek);
        let difference = diffHoursMinutes(planned, this.overview.total);

        this.overview = {
            total: this.overview.total,
            planned: planned,
            difference: difference,
        }
    }
}
