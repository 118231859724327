import { Component } from '../../common/decorators';
import { HourMinTime } from './Time.component';

@Component({
    bindings: {
        time: '<'
    },
    template: `
    <ng-md-icon ng-if="$ctrl.isDiffZero() && !$ctrl.timeIsNan()" icon="done" size="24px"></ng-md-icon>

    <div ng-if="$ctrl.timeIsNan()"> 
        <span>?</span>
    </div>
    <div ng-if="!$ctrl.isDiffZero() && !$ctrl.timeIsNan()"> 
        <span ng-if="$ctrl.time.hours !== 0">{{$ctrl.time.hours}}h</span>&nbsp;<span>{{$ctrl.time.minutes}}min</span>
    </div>
    `
})
export class TimeLabelComponent {

    time: HourMinTime;

    isDiffZero(): boolean {
        return this.time.hours === 0 && this.time.minutes === 0;
    }

    timeIsNan(): boolean {
        return isNaN(this.time.hours) || isNaN(this.time.minutes);
    }

}