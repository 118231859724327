import { Component } from '../common/decorators';

import { TimeLabelComponent } from '../common/components/TimeLabel.component';
import { HourMinTime } from '../common/components/Time.component';

import '../common/components/TimeLabel.component';
import '../common/components/FromToTime.component';

export interface Overview {
    total: HourMinTime;
    planned: HourMinTime;
    difference: HourMinTime;
}
type resultStatus = "ok" | "below";

@Component({
    bindings: {
        overview: '<',
        diameter: '<'
    },
    template: `
    
        <div class="separator">
            <md-progress-circular class="background" md-mode="determinate" md-diameter="{{$ctrl.diameter}}" value="50"></md-progress-circular>
            <md-progress-circular ng-class="$ctrl.colourResult()" class="indicator" md-mode="determinate" md-diameter="{{$ctrl.diameter}}" value="{{$ctrl.completion()}}"></md-progress-circular>
       </div>
       <mft-time-label ng-class="$ctrl.colourResult()" time="$ctrl.overview.difference" id="overview-difference"></mft-time-label>
       <mft-from-to-time current="$ctrl.overview.total" goal="$ctrl.overview.planned" status="$ctrl.colourResult()"><mft-from-to-time/>
    `
})
export class OverviewComponent {

    overview: Overview;

    colourResult(): resultStatus {

        let timeDiff = this.overview.difference
        return (timeDiff.hours >= 0 && timeDiff.minutes >= 0) ? "ok" : "below";
    }

    completion(): number {
        let totalMins = this.overview.total.hours * 60 + this.overview.total.minutes;
        let plannedMins = this.overview.planned.hours * 60 + this.overview.planned.minutes;
        let completion = (totalMins / plannedMins);

        return completion >= 1 ? 50 : completion * 50;
    }
}