
import { Component } from '../../common/decorators';

export interface DayInfo {
    startingTime: Date;
    finishingTime: Date;
    recessMins: number;
}

export enum FieldToUpdate { STARTING_TIME, FINISHING_TIME, RECESS_MINS }

@Component({
    require: {
        ngModel: 'ngModel'
    },
    bindings: {
        update: '&'
    },
    template: `
    <ng-form novalidate name="$ctrl.formModel" layout="column">
        <div layout="row" class="day-info-time-container">
            <md-input-container>
                <label>Start</label>
                <input type="time" name="startingTime" ng-model="$ctrl.startingTime" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 300, 'blur': 0 } }" required/>
                <div ng-messages="$ctrl.formModel.startingTime.$error">
                    <div class="error-message" ng-message="required">Tell us when you usually start working.</div>
                    <div class="error-message" ng-message="time">This time is not valid.</div>
                </div>
            </md-input-container>
            <ng-md-icon icon="trending_flat" class="day-info-arrow" size="24px"></ng-md-icon>
            <md-input-container>
                <label>Finish</label>
                <input type="time" name="finishingTime" ng-model="$ctrl.finishingTime" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 300, 'blur': 0 } }" required/>
                <div ng-messages="$ctrl.formModel.finishingTime.$error">
                    <div class="error-message" ng-message="required">Tell us when you usually start working.</div>
                    <div class="error-message" ng-message="time">This time is not valid.</div>
                </div>
            </md-input-container> 
        </div>
        <div ng-messages="$ctrl.ngModel.$error">
            <div class="error-message" ng-message="finishesBeforeStarting">You cannot finish work before even start!.</div>
        </div>
        <div class="day-info-recess-container">       
            <md-input-container class="day-info-recess">
                <label>Recess (mins)</label>
                <input name="recessMins" ng-model="$ctrl.recessMins" ng-model-options="{ updateOn: 'default blur', debounce: { 'default': 300, 'blur': 0 } }" type="number" min="0" 
                 ng-pattern="/^[0-9]*$/"required/>
                <div ng-messages="$ctrl.formModel.recessMins.$error">
                    <div class="error-message" ng-message="required">Tell us how many minutes you spend on recess. If none, please input 0.</div>
                    <div class="error-message" ng-message="min">Negative minutes? I can't cope with this new dimension. Please add a number greater or equal to 0.</div>
                    <div class="error-message" ng-message="number">This is clearly not a valid number. Try using numbers bigger than 0.</div>
                    <div class="error-message" ng-message="pattern">This is clearly not a valid input. Try using numbers bigger than 0.</div>
                </div>
            </md-input-container>
        </div>
        <div ng-messages="$ctrl.ngModel.$error">
            <div class="error-message" ng-message="recessBiggerThanWorkingHours">That must be a great job! The rest of us take breaks smaller than our working time.</div>
        </div>
    </ng-form>
   `
})
export class DayInfoComponent {

    ngModel: ng.INgModelController;
    update: (fieldToUpdate: any) => void;

    _startingTime: Date;
    _finishingTime: Date;
    _recessMins: number;

    get startingTime(): Date {
        return this._startingTime;
    };
    get finishingTime(): Date {
        return this._finishingTime;
    };
    get recessMins(): number {
        return this._recessMins;
    };

    set startingTime(value) {
        this._startingTime = value;
        this.manageChange(FieldToUpdate.STARTING_TIME);
    };
    set finishingTime(value) {
        this._finishingTime = value;
        this.manageChange(FieldToUpdate.FINISHING_TIME);
    };
    set recessMins(value) {
        this._recessMins = value;
        this.manageChange(FieldToUpdate.RECESS_MINS);
    };
    /** @ngInject */
    constructor(public $scope: ng.IScope) {

    }

    $onInit() {

        this.$scope.$watch(() => JSON.stringify(this.ngModel.$modelValue)
            , () => this.ngModel.$render());

        this.ngModel.$render = () => {

            let modelValue = this.ngModel.$modelValue;

            if (modelValue) {
                this._startingTime = modelValue.startingTime;
                this._finishingTime = modelValue.finishingTime;
                this._recessMins = modelValue.recessMins;
            }
        };

        this.ngModel.$parsers.push((viewValue) => {
            if (!viewValue.finishingTime || !viewValue.startingTime || (!viewValue.recessMins && viewValue.recessMins < 0)) {
                return undefined;
            }
            return viewValue;
        });

        this.ngModel.$validators['finishesBeforeStarting'] = (modelValue: DayInfo, viewValue: DayInfo) => {
            if (!modelValue.finishingTime || !modelValue.startingTime) {
                return true;
            }
            return modelValue.finishingTime > modelValue.startingTime;
        };

        this.ngModel.$validators['recessBiggerThanWorkingHours'] = (modelValue: DayInfo, viewValue: DayInfo) => {
            if (!modelValue.finishingTime || !modelValue.startingTime || (!modelValue.recessMins && modelValue.recessMins < 0)) {
                return true;
            }
            let totalWorkingMs: number = modelValue.finishingTime.valueOf() - modelValue.startingTime.valueOf();
            let recessMs = modelValue.recessMins * 60000;

            return totalWorkingMs > recessMs;
        };
    }

    manageChange(fieldToUpdate) {
        let viewValue: DayInfo = {
            startingTime: this._startingTime,
            finishingTime: this._finishingTime,
            recessMins: this._recessMins
        }

        this.ngModel.$setViewValue(viewValue);

        if (this.ngModel.$valid) {
            this.update({ fieldToUpdate: fieldToUpdate });
        }
    }
}
