import { HourMinTime } from '../common/components/time.component';

export function realWorkingMinutes(startingTime: Date, finishingTime: Date, recessMins: number): number {

    let totalMinutes = finishingTime.getMinutes() - startingTime.getMinutes() - recessMins;
    let totalHoursInMinutes = finishingTime.getHours() * 60 - startingTime.getHours() * 60;

    return totalMinutes + totalHoursInMinutes;
}

export function minutesToHoursMinutes(mins: number): HourMinTime {

    let totalHours = Math.floor(mins / 60);
    let totalMins = Math.abs(mins % 60);

    return {
        hours: totalHours,
        minutes: totalMins
    }
}

export function hoursToHoursMinutes(hours: number): HourMinTime {

    let totalHours = Math.floor(hours);
    let totalMins = (hours - totalHours) * 60;

    return {
        hours: totalHours,
        minutes: totalMins
    }
}

export function diffHoursMinutes(planned: HourMinTime, total: HourMinTime): HourMinTime {

    let totalMins = total.minutes + total.hours * 60;
    let plannedMins = planned.minutes + planned.hours * 60;
    let result = totalMins - plannedMins;

    let resultHours = parseInt(`${result / 60}`);
    let resultMinutes = result % 60;

    return {
        hours: resultHours === 0 ? 0 : resultHours,
        minutes: resultHours < 0 ? Math.abs(resultMinutes) : resultMinutes
    }
}
