import { BehaviorSubject } from 'rxjs/Rx';
import { DayInfo, FieldToUpdate } from '../common/components/DayInfo.component';

export interface SelectedDay {
    name: string;
    shortName: string;
    enabled: boolean;
}

export class ScheduleConfig {
    dayInfo: DayInfo;

    hoursPerWeek: number;
    hoursPerWeek$: BehaviorSubject<number>;

    startingTime$: BehaviorSubject<Date>;
    finishingTime$: BehaviorSubject<Date>;
    recessMins$: BehaviorSubject<number>;

    changedDays$: BehaviorSubject<SelectedDay>;
    days: SelectedDay[];

    constructor(days: SelectedDay[]) {
        this.hoursPerWeek = 40;
        this.hoursPerWeek$ = new BehaviorSubject<number>(this.hoursPerWeek);

        this.days = days;
        this.changedDays$ = new BehaviorSubject<SelectedDay>(null);

        this.dayInfo = {
            startingTime: new Date(2016, 10, 2, 8, 30, 0),
            finishingTime: new Date(2016, 10, 2, 17, 0, 0),
            recessMins: 30
        }

        this.startingTime$ = new BehaviorSubject<Date>(this.dayInfo.startingTime);
        this.finishingTime$ = new BehaviorSubject<Date>(this.dayInfo.finishingTime);
        this.recessMins$ = new BehaviorSubject<number>(this.dayInfo.recessMins);
    }
}