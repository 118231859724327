import { Component } from '../../common/decorators';

import { TimeLabelComponent } from './TimeLabel.component';
import './TimeLabel.component';

@Component({
  bindings: {
    goal: '<',
    current: '<',
    status: '<'
  },
  template: `
        <div class="time-summary" flex>

          <div class="time-info current">
            <div class="tag">
              <ng-md-icon icon="av_timer" size="19"></ng-md-icon>
              <span>Current</span>
            </div>
            <mft-time-label  class="{{$ctrl.status}} value" time="$ctrl.current" ></mft-time-label>
          </div>

          <div class="time-info goal">
            <div class="tag">
              <ng-md-icon icon="flag" size="19"></ng-md-icon>
              <span>Goal</span>
            </div>
            <mft-time-label class="value" time="$ctrl.goal"></mft-time-label>
          </div>

        </div>
   `
})
export class FromToTimeComponent {

}